<template>
  <div class="h-100">
    <b-card
      style="cursor: pointer !important"
      class="h-100"
      border-variant="dark"
      bg-variant="dark"
      footer-bg-variant="dark"
      text-variant="light"
      :footer="item.contentName"
      @click="$bvModal.show(item.id)"
    >
      <b-img-lazy
        v-bind="mainProps"
        :src="contentImage"
        onerror="this.style.display='none'"
      />
      <b-icon
        :key="item.id"
        class="absolute-center mt-2"
        font-scale="2.5"
        icon="play"
        aria-hidden="true"
      ></b-icon>
    </b-card>
    <b-modal
      hide-footer
      :key="item.id"
      :hide-header="$store.getters.fullScreenAsDefault"
      :id="item.id"
      header-bg-variant="dark"
      header-text-variant="light"
      footer-text-variant="light"
      footer-bg-variant="dark"
      body-bg-variant="dark"
      body-text-variant="light"
      variant="dark"
      :title="item.contentName"
    >
      <Player :contentID="item.id" />
    </b-modal>
  </div>
</template>
<script>
import { mapState } from "vuex"

export default {
  props: ["item"],
  components: { Player: () => import("./common/Media/Player") },
  data() {
    return {
      mainProps: {
        center: true,
        fluidGrow: true,
        blank: true,
        blankColor: "#bbb",
        width: "100%",
        height: "100%",
      },
    }
  },
  methods: {
    fireTvKeyEvents(event) {
      if (event.keyCode === 27) {
        console.log(event.keyCode + " exit video screen back to library")
        return this.$bvModal.hide(this.item.id)
      }
    },
  },
  computed: {
    ...mapState(["contentMetaData", "cdnPreviewRoute", "deliveryDomain"]),
    displayPlayButton() {
      let type = this.item.transactionInfo?.purchaseOptions[0]?.type
      return type !== "subscription" && type !== "rental"
    },
    contentImage() {
      return `${this.deliveryDomain}${this.cdnPreviewRoute}${this.item.id}/${
        this.item.id
      }_image_cover.png?t${Date.now()}`
    },
  },
  mounted() {
    window.addEventListener("keyup", this.fireTvKeyEvents)
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.fireTvKeyEvents)
  },
}
</script>
<style>
.modal-content {
  width: 100% !important;
  height: 100% !important;
  border: none !important;
  border-radius: 0 !important;
}
.modal {
  padding: 0 !important;
}
.modal-dialog {
  margin: 0 !important;
  max-width: 100vw !important;
}
.modal-body {
  padding: 0 !important;
}
.modal-header {
  border-bottom: none !important;
}

.modal-title {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card-footer {
  font-size: 15px;
  background: #fff;
}
</style>
